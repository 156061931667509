<template>
<div>
   <div class="row mt-2">
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Genero</label>
            <select class="form-control" v-model="genero">
                <option value="F">F</option>
                <option value="M">M</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Edad</label>
            <input type="number" class="form-control" v-model="edad_u">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">TA. Sistolica</label>
            <input type="number" class="form-control" v-model="ta_sistolica">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Col Total</label>
            <input type="number" class="form-control" v-model="col_total">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Col HDL</label>
           <input type="number" class="form-control" v-model="col_hdl">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Tratamientop Hipertension</label>
            <select class="form-control" v-model="tratamiento_hipertension">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Fuma Cigarrillos</label>
            <select class="form-control" v-model="fuma_cigarrillos">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Presencia Diabetes</label>
            <select class="form-control" v-model="presencia_diabetes">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>

        
        
   </div>
   <div class="row">
       <div class="col-6">
           porcentaje : {{this.porcentaje}}
       </div>
        <div class="col-6">
           Riesgo : {{this.riesgo}}
       </div>
   </div>
   <div class="row mt-2">
       
        <button class="btn btn-primary mx-2 btn-sm" @click="calcular();">Calcular</button>
    
    
        <button class="btn btn-success btn-sm" @click="guardar();">Guardar</button>
       
   </div>
</div>

</template>
<script>
import fragmingamService from '../../../../services/fragmingamService';
import {framingham_risk_score} from '../../../../utils/utils';
import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import dayjs from 'dayjs';
import examenFisicoService from '../../../../services/examenFisicoService';
export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    data() {
        return {
            
                genero:'',
                
                col_total:'',
                col_hdl:'',
                presencia_diabetes:'',
                ta_sistolica:'',
                tratamiento_hipertension:'',
                fuma_cigarrillos:'',
                porcentaje:'',
                riesgo:'',
                id_fragmingam:''
            
        }
    },
    methods: {
        async calcular(){
            try {

                const gender = this.genero === 'F' ? 'female' : 'male';

                const riesgo = framingham_risk_score(gender,
                    this.edad_u,
                    this.ta_sistolica,
                    this.col_total,
                    this.col_hdl,
                    this.tratamiento_hipertension,
                    this.fuma_cigarrillos,
                    this.presencia_diabetes,
                )

console.log(riesgo);
            this.riesgo = riesgo['classification'];
            this.porcentaje = riesgo['risk'];
    
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async guardar(){
            try {

                let findrisc_h={
                    id_historia: this.idHistoria,
                    genero:this.genero,
                    edad:this.edad_u,
                    ta_sistolica:this.ta_sistolica,
                    col_total:this.col_total,
                    col_hdl:this.col_hdl,
                    presencia_diabetes:this.presencia_diabetes,
                    tratamiento_hipertension:this.tratamiento_hipertension,
                    fuma_cigarrillos:this.fuma_cigarrillos,
                    riesgo:this.riesgo,
                    porcentaje:this.porcentaje,
                }

                this.LoaderSpinnerShow();

                if(this.id_fragmingam!==''){
                    findrisc_h['id']=this.id_fragmingam;
                    await fragmingamService.update(findrisc_h); 
                }else{
                    await fragmingamService.store(findrisc_h); 
                    const response=await fragmingamService.showByHc(this.idHistoria);
                    this.id_fragmingam=response.data.id;
                }
                
                
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                 Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async cargarTaSistolica(){
            const response = await examenFisicoService.showByHc(this.idHistoria);
            this.ta_sistolica= response.data.pa_sistolica;
        }
    },
    async created(){
        this.genero=this.sexo;
        if(this.finalizada==='N'){
            const response=await fragmingamService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.edad_u=response.data.edad;
                this.col_total=response.data.col_total;
                this.col_hdl=response.data.col_hdl;
                this.presencia_diabetes=response.data.presencia_diabetes;
                this.tratamiento_hipertension=response.data.tratamiento_hipertension;
                this.ta_sistolica=response.data.ta_sistolica;
                this.fuma_cigarrillos=response.data.fuma_cigarrillos;
                this.porcentaje=response.data.porcentaje;
                this.riesgo=response.data.riesgo;
                this.id_fragmingam=response.data.id;
            } 
        }
        this.cargarTaSistolica();
    },
    computed: {
        edad_u : function(){
            if(isEmpty(this.fecha_nacimiento)) return 0;
            return dayjs().diff(this.fecha_nacimiento, 'year');
        },
    }
}
</script>