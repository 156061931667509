<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Examenes</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <tr >
                            <th>Procedimiento</th>
                            <th>Fecha</th>
                            <th>Resultado</th>
                        </tr>
                        <tr v-for="item in items_procedimientos" :key="item.id" >
                            <td>{{item.procedimiento.servicio.descripcion}}</td>
                            <td>{{ item.fecha }}</td>
                            <td>{{ item.resultado }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historiaControlExamenService from "../../../../services/historiaControlExamenService";
import examenProcedimientoService from '../../../../services/examenProcedimientoService';
import {isEmpty} from "lodash";

export default {
    props: ['idHistoria','finalizada','idUsuario'],
    data() {
        return {
            usuario: {},
            procedimientos: [],
            items_procedimientos: [],
            item_procedimiento:{
                procedimiento:{},
                resultado:'',
                fecha:''
            },
        };
    },
    methods: {
        async cargarProcedimientos(){
            const response = await examenProcedimientoService.index();
            this.procedimientos = response.data;
        },   
    },
    async created() {
        this.cargarProcedimientos();
        const response=await historiaControlExamenService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
            this.items_procedimientos = response.data.map(x=>{
                return {
                    id:x.id,
                    fecha:x.fecha,
                    resultado:x.resultado,
                    procedimiento:x.examen
                }
            });        
        } 
    },
}
</script>