<template>
  <main>
    <div class="">
      <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Situaciones Personales</span> </div>
      <div class="card-body">
        <table class="table table-striped table-sm table-bordered  ">
          <tr>
            <th>Situacion</th>
            <th>Respuesta</th>
          </tr>
          <tr v-for="item in situaciones" :key="item.id">
            <td>{{ item.situacion }}</td>
            <td>
              {{item.respuesta}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </main>
</template>
<script>
import situacionesPersonalesService from "../../../../services/situacionesPersonalesService";
import historiaSituacionesPersonalesService from "../../../../services/historiaSituacionesPersonalesService";
import {isEmpty} from "lodash";

export default {
  props: ["idHistoria", "finalizada", "idUsuario"],
  data() {
    return {
      respuesta: {},
      situaciones: {},
      tieneRespuestas : false
    };
  },
  methods: {
    async cargarSituaciones() {
      
      const response = await situacionesPersonalesService.index();
      const respuestas = await historiaSituacionesPersonalesService.showByHc(this.idHistoria);

      if(!isEmpty(respuestas.data)) this.tieneRespuestas = true;
      
      this.situaciones = response.data.map((situacion) => {
            
            let respuesta = '';

            if(!isEmpty(respuestas.data)){
                
                const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_situacion) === parseInt(situacion.id));
                
                if(tieneRespuesta !== undefined){
                    respuesta = tieneRespuesta.respuesta;
                }

            }

            return {
                ...situacion,
                respuesta: respuesta,
            };
      });
    },
  },
  created() {
    this.cargarSituaciones();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>