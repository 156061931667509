const factors = {
  male: {
    age: [
      { lower: 0, higher: 45, points: 0 },
      { lower: 45, higher: 54, points: 2 },
      { lower: 54, higher: 64, points: 3 },
      { lower: 64, higher: 110, points: 4 },
    ],
    bmi: [
      { lower: 0, higher: 25, points: 0 },
      { lower: 25, higher: 30, points: 1 },
      { lower: 30, higher: 100, points: 3 },
    ],
    waist_circunference: [
      { lower: 0, higher: 94, points: 0 },
      { lower: 94, higher: 102, points: 3 },
      { lower: 102, higher: 250, points: 4 },
    ],
    bp_medication: {
      n: 0,
      y: 2,
    },
    history_blood_glucose: {
      n: 0,
      y: 5,
    },
    physical_activity: [
      { lower: 0, higher: 4, points: 2 },
      { lower: 4, higher: 100, points: 0 },
    ],
    consumption_vegetable_fruits: {
      n: 1,
      y: 0,
    },
    family_history_diabetes: {
      n: 0,
      "2g": 3,
      "1g": 5,
    },
  },
  female: {
    age: [
      { lower: 0, higher: 45, points: 0 },
      { lower: 45, higher: 54, points: 2 },
      { lower: 54, higher: 64, points: 3 },
      { lower: 64, higher: 110, points: 4 },
    ],
    bmi: [
      { lower: 0, higher: 25, points: 0 },
      { lower: 25, higher: 30, points: 1 },
      { lower: 30, higher: 100, points: 3 },
    ],
    waist_circunference: [
      { lower: 0, higher: 80, points: 0 },
      { lower: 80, higher: 88, points: 3 },
      { lower: 88, higher: 200, points: 4 },
    ],
    bp_medication: {
      n: 0,
      y: 2,
    },
    history_blood_glucose: {
      n: 0,
      y: 5,
    },
    physical_activity: [
      { lower: 0, higher: 4, points: 2 },
      { lower: 4, higher: 100, points: 0 },
    ],
    consumption_vegetable_fruits: {
      n: 1,
      y: 0,
    },
    family_history_diabetes: {
      n: 0,
      "2g": 3,
      "1g": 5,
    },
  },
};

const risk_classification = [
  { lower: 0, higher: 7, risk: "BAJO" },
  { lower: 8, higher: 11, risk: "LIGERAMENTE ALTO" },
  { lower: 12, higher: 14, risk: "MODERADO" },
  { lower: 15, higher: 20, risk: "ALTO" },
  { lower: 21, higher: 25, risk: "MUY ALTO" },
];

const findrisc = (
  gender,
  age,
  bmi,
  waist_circunference,
  bp_medication,
  history_blood_glucose,
  physical_activity,
  consumption_vegetable_fruits,
  family_history_diabetes
) => {
  const _factors = factors[gender];

  const age_points =
    _factors.age.find((x) => age >= x.lower && age < x.higher)?.points || 0;

  const bmi_points =
    _factors.bmi.find((x) => bmi >= x.lower && bmi < x.higher)?.points || 0;

  const waist_circunference_points =
    _factors.waist_circunference.find(
      (x) => waist_circunference >= x.lower && waist_circunference < x.higher
    )?.points || 0;

  const bp_medication_points = _factors.bp_medication[bp_medication];

  const history_blood_glucose_points =
    _factors.history_blood_glucose[history_blood_glucose];

  const physical_activity_points =
    _factors.physical_activity.find(
      (x) => physical_activity >= x.lower && physical_activity < x.higher
    )?.points || 0;

  const consumption_vegetable_fruits_points =
    _factors.consumption_vegetable_fruits[consumption_vegetable_fruits];

  const family_history_diabetes_points =
    _factors.family_history_diabetes[family_history_diabetes];

  const total_points = [
    age_points,
    bmi_points,
    waist_circunference_points,
    bp_medication_points,
    history_blood_glucose_points,
    physical_activity_points,
    consumption_vegetable_fruits_points,
    family_history_diabetes_points,
  ].reduce((t,c) => t+c);

  const classification = (risk_classification.find(x => total_points >= x.lower  && total_points <= x.higher))?.risk || 'NAN';

  return {
      points : total_points,
      risk : classification
  };
};

export default findrisc
