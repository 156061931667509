<template>
    <main>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">FINNIS RISK</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">FRAGMINGAM</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false"></a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <indexFinnisRisk :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :sexo="sexo" :fecha_nacimiento="fecha_nacimiento"></indexFinnisRisk>
            
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <indexFragmingam :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :sexo="sexo" :fecha_nacimiento="fecha_nacimiento"></indexFragmingam>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
            
        </div>
        </div>
    
    </main>
    
</template>
<script>
import indexFinnisRisk from "../base/baseFinnisRisk/indexFinnisRisk";
import indexFragmingam from "../base/baseFragmingam/indexFragmingam";
export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    components:{indexFinnisRisk,indexFragmingam},
}
</script>

