//TFG = 141 * Math.min(Creatinina sérica/kappa1),alpha * Math.max(Creatinina sérica/kappa1),-1.209 * 0.993Edad * Sexo * Raza

const factors = {
    female: {
        gender: 1.018,
        alpha : -0.329,
        kappa : 0.7,
        race : {
            white_and_others : 1,
            black : 1.159
        }
    },
    male: {
        gender: 1,
        alpha : -0.411,
        kappa : 0.9,
        race : {
            white_and_others : 1,
            black : 1.159
        }
    }
}

//gender [male, female];
// race [white_and_others, black]

const glomerularFiltracionRate = (gender, race, age, serum_creatitine) => {

    const _factors = factors[gender];

    const gfr = 141 * Math.pow(Math.min(serum_creatitine/_factors.kappa),_factors.alpha) 
    * Math.pow(Math.max(serum_creatitine/_factors.kappa),-1.209) 
    * Math.pow(0.993,age) 
    * _factors.gender 
    * _factors.race[race]
    
    return gfr
}

//glomerularFiltracionRate('male', 'black', 28, 0.90);

export default glomerularFiltracionRate
