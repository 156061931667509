<template>
    <main>
      <div class="form-row mb-2 mt-2 border-bottom">
        <div class="col-4">
          <strong>Examen/Procedimiento</strong>
        </div>
        <div class="col-4">
          <strong>Fecha</strong>
        </div>
        <div class="col-4">
          <strong>Resultado</strong>
        </div>
      </div>
      <div class="form-row mb-1 mt-1" v-for="item in procedimientos" :key="item.id">
        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">{{item.descripcion}}</div>
        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6">
          <label class="d-none d-sm-block d-md-none small">Fecha</label>
          <input type="date" v-model="item.fecha" class="form-control form-control-sm">
        </div>
        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6">
          <label class="d-none d-sm-block d-md-none small">Resultado</label>
          <input :type="item.tipo" v-model="item.resultado" class="form-control form-control-sm">
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 d-flex justify-content-end">
          <button class="btn btn-success" @click="save()">Guardar</button>
        </div>
      </div>
    </main>
</template>
<script>
import historiaControlExamenService from "../../../../services/historiaControlExamenService";
import Swal from "sweetalert2";
import examenProcedimientoService from '../../../../services/examenProcedimientoService';
import {isEmpty} from "lodash";

import {required} from "vuelidate/lib/validators";
import Toast from "@/components/common/utilities/toast";

export default {
    props: ['idHistoria','finalizada','idUsuario', 'idTipoHistoria', 'usuario'],
    data() {
        return {
            procedimientos: [],
            items_procedimientos: [],
            item_procedimiento:{
                procedimiento:{},
                resultado:'',
                fecha:''
            },
            embarazo_actual: {},
        };
    },
    validations (){
        return {
            item_procedimiento:{
                procedimiento:{required},
                resultado:{required},
                fecha:{required},
            },
        }
    },
    methods: {
        async cargarProcedimientos(){

          const response = await examenProcedimientoService.index({
            fecha_nacimiento : this.usuario.fecha_nacimiento,
            id_tipo_historia : this.idTipoHistoria,
            sexo : this.usuario.sexo
          });

          this.procedimientos = response.data.map(x => {
            return {
              ...x,
              fecha : '',
              resultado : '',
            }
          });

        },
        async save() {
            try {

                if(!this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '').length) return;

                let historia_control = {
                    id_historia:this.idHistoria,
                    procedimientos: this.procedimientos.filter(x => x.resultado !== '' && x.fecha !== '')
                };

                this.LoaderSpinnerShow();

                await historiaControlExamenService.store(historia_control);

                this.LoaderSpinnerHide();

              Toast.fire({
                icon: 'success',
                title: 'Datos guardados con exito'
              });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
              Toast.fire({
                icon: 'error',
                title: 'ha ocurrido un error al procesar la solicitud'
              });
            }
        },
        limpiarCampos(){
            this.item_procedimiento.resultado='';
            this.item_procedimiento.procedimiento={}
        },
        async eliminar(id) {
            try {
                this.LoaderSpinnerShow();
                await historiaControlExamenService.delete(id);
                this.LoaderSpinnerHide();
                Swal.fire('Datos Eliminados con exito','', 'success');
                let i = this.items_procedimientos.findIndex(x=>x.id === id);
                this.items_procedimientos.splice(i, 1);
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire("Ups!", "ha ocurrido un error al procesar la solicitud", "error");
            }
        },
    },
    async created() {
        await this.cargarProcedimientos();
        const response=await historiaControlExamenService.showByHc(this.idHistoria);

        if(!isEmpty(response.data)){

          response.data.forEach((x) => {
            let index = this.procedimientos.findIndex(y => parseInt(y.id) === parseInt(x.examen.id));
            if(index !== -1){
              this.procedimientos[index].resultado = x.resultado;
              this.procedimientos[index].fecha = x.fecha;
            }
          });

        }
    },
}
</script>
