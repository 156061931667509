const factors = {
    male: {
        age: 3.06117,
        col_total: 1.12370,
        col_hdl: -0.93263,
        avg_risk: 23.9802,
        period_risk_factor: 0.88936,
        sis_bp_factor: {
            y: 1.99881,
            n: 1.93303
        },
        cig: {
            y: 0.65451,
            n: 0
        },
        dm: {
            y: 0.57367,
            n: 0
        }
    },
    female: {
        age: 2.32888,
        col_total: 1.20904,
        col_hdl: -0.70833,
        avg_risk: 26.1931,
        period_risk_factor: 0.95012,
        sis_bp_factor: {
            y: 2.82263,
            n: 2.76157
        },
        cig: {
            y: 0.52873,
            n: 0
        },
        dm: {
            y: 0.69154,
            n: 0
        }
    }
}

const risk_classification = [
    { lower: 0, higher: 10, risk: 'BAJO' },
    { lower: 11, higher: 20, risk: 'MEDIO' },
    { lower: 20, higher: 100, risk: 'ALTO' },
]

const framingham_risk_score = (gender, age, ta_sis, col_total, col_hdl, bp_treatment = 'n', cig = 'n', dm = 'n') => {

    const _factors = factors[gender];

    const risk_factors = (Math.log(age) * _factors.age) +
        (Math.log(col_total) * _factors.col_total) + (Math.log(col_hdl) * _factors.col_hdl) +
        (Math.log(ta_sis) * _factors.sis_bp_factor[bp_treatment]) +
        _factors.cig[cig] +
        _factors.dm[dm] -
        _factors.avg_risk;

    const risk = 100 * (1 - Math.pow(_factors.period_risk_factor, Math.exp(risk_factors)));

    const _risk_classification = risk_classification.find(x => risk >= x.lower && risk <= x.higher);

    return {
        risk: Number(risk.toFixed(2)),
        classification: _risk_classification.risk
    };
}

export default framingham_risk_score