<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> FinnishRisk</span> </div>
        <div class="card-body">
            <div class="row mt-2">     
                    <div class="col-6">Edad: {{edad_u}}</div>
                    <div class="col-6">Imc: {{imc}}</div>
                    <div class="col-6">Medicamentos Presion: {{medicamentos_presion}}</div>
                    <div class="col-6">Historial Glucosa: {{historial_glucosa}}</div>
                    <div class="col-6">Actividad Fisica: {{actividad_fisica}}</div>
                    <div class="col-6">Consumo diario de Frutas: {{consumo_diario_frutas}}</div>
                    <div class="col-6">Historial Diabetes: {{historial_diabetes}}</div>
                    <div class="col-6">Perimetroi abdominal: {{perimetro_abdominal}}</div>
                    <div class="col-6">Puntaje: {{puntaje}}</div>
                    <div class="col-6">Riesgo: {{riesgo}}</div>  
            </div>
        </div>
    </div>  
</template>
<script>
import findriscService from '../../../../services/findriscService';
import {isEmpty} from "lodash";


export default {
    props: ['idHistoria','idUsuario'],
    data() {
        return {
            
                genero:'',
                edad_u:'',
                imc:'',
                medicamentos_presion:'',
                historial_glucosa:'',
                actividad_fisica:'',
                consumo_diario_frutas:'',
                historial_diabetes:'',
                puntaje:'',
                riesgo:'',
                perimetro_abdominal:'',
                peso:'',
                talla:'',
                id_findrisc:''
            
        }
    },
    async created(){
        this.genero=this.sexo;
            const response_findrisc=await findriscService.showByHc(this.idHistoria);
                if(!isEmpty(response_findrisc.data)){
                    this.edad_u=response_findrisc.data.edad;
                    this.medicamentos_presion=response_findrisc.data.medicamentos_presion;
                    this.historial_glucosa=response_findrisc.data.historial_glucosa;
                    this.actividad_fisica=response_findrisc.data.actividad_fisica;
                    this.imc=response_findrisc.data.imc;
                    this.consumo_diario_frutas=response_findrisc.data.consumo_diario_frutas;
                    this.historial_diabetes=response_findrisc.data.historial_diabetes;
                    this.perimetro_abdominal=response_findrisc.data.perimetro_abdominal;
                    this.puntaje=response_findrisc.data.puntaje;
                    this.riesgo=response_findrisc.data.riesgo;
                    this.id_findrisc=response_findrisc.data.id;
                } 
            }
    

}
</script>